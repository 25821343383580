import { buttonBaseClasses } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'
import Services from '../api/Services';
import CredentialStorage from '../components/CredentialStorage';
import Swal from 'sweetalert2';
import { Howl } from 'howler';
import apple from '../sounds/Apple.mp3';
import ipad from '../sounds/Message_S4.mp3';

const Movements = () => {

/* sound play configuration */
function SoundPlay() {
  const Sounds = new Howl({
    src: [apple]
  })
  Sounds.play()
}

function SoundPlayUtility() {
  const Sounds = new Howl({
    src: [ipad]
  })
  Sounds.play()
}
/* fin sound play */

/* this procedement is to save a new sale */
const userIdInsert = CredentialStorage.getUserId();
  const [formError, setFormerror] = useState({});
  const [formErrorBuy, setFormerrorBuy] = useState({});
  const [formErrorUtility, setFormerrorUtility] = useState({});
  const [requestError, setRequestError] = useState({});
  const [total, setTotal] = useState('');
  const [sale, setSale] = useState({
    description: "",
    amount: "",
    price: "",
    total: "",
    confirmed: "income",
    user_id: userIdInsert
  });

  const handleChange = (e) => {
      const {name, value} = e.target;
      setSale(prevState => ({
          ...prevState,
          [name] : value
      }));

    }

  const handleKeyDown = (e) =>{
    if(e.key === 'Enter'){
      const errors ={};
      if(sale.amount == 0){
        errors.amount ='El campo cantidad no puede estar vacio';
      }
    
      if(sale.price == 0){
        errors.price ='El campo precio no puede estar vacio';
      }
    
      if (!setFormerror(errors)) {
        if (!(Object.entries(errors) == 0)) {
          return console.log("llena los campos vacios calcular");
        }     
      }
      
      const total = (sale.amount * sale.price);
      setTotal(total.toFixed(2));
      sale.total = total.toFixed(2);
    }
  }

  const handleCalculateSale = () =>{
    const errors ={};
    if(sale.amount == 0){
      errors.amount ='El campo cantidad no puede estar vacio';
    }
  
    if(sale.price == 0){
      errors.price ='El campo precio no puede estar vacio';
    }
  
    if (!setFormerror(errors)) {
      if (!(Object.entries(errors) == 0)) {
        return console.log("llena los campos vacios calcular");
      }     
    }

      const total = (sale.amount * sale.price);
      setTotal(total.toFixed(2));
      sale.total = total.toFixed(2);
  }
/* this procedement is to save a new sale */

/* this procedement is to save a new buy */
const [buy, setBuy] = useState({
  description: "",
  amount: "",
  price: "",
  total: "",
  confirmed: "outflow",
  user_id: userIdInsert
});
const [totalBuy, setTotalBuy] = useState();

const handleChangeBuy = (e) => {
  const {name, value} = e.target;
  setBuy(prevState => ({
      ...prevState,
      [name] : value
  }));
}

const handleKeyDownBuy = (e) =>{
  if(e.key === 'Enter'){

    const errors ={};
    if(buy.amount == 0){
      errors.amount ='El campo cantidad no puede estar vacio';
    }
  
    if(buy.price == 0){
      errors.price ='El campo precio no puede estar vacio';
    }
  
    if (!setFormerrorBuy(errors)) {
      if (!(Object.entries(errors) == 0)) {
        return console.log("llena los campos vacios calcular");
      }     
    }

    const totalBuy = (buy.amount * buy.price);
    setTotalBuy(totalBuy.toFixed(2));
    buy.total = totalBuy.toFixed(2);
  }
}

const handleCalculateBuy = () =>{
  const errors ={};
  if(buy.amount == 0){
    errors.amount ='El campo cantidad no puede estar vacio';
  }

  if(buy.price == 0){
    errors.price ='El campo precio no puede estar vacio';
  }

  if (!setFormerrorBuy(errors)) {
    if (!(Object.entries(errors) == 0)) {
      return console.log("llena los campos vacios calcular");
    }     
  }

    const totalBuy = (buy.amount * buy.price);
    setTotalBuy(totalBuy.toFixed(2));
    buy.total = totalBuy.toFixed(2);
}
/* this procedement is to save a new sale */

/* here let's go create all functionality to insert a new sales or buy */
async function insertPettyCash(flag){
  if(flag == 'sale'){
    if (!setFormerror(validate(sale, flag))) {
      const errors = validate(sale, flag);
      if (!(Object.entries(errors) == 0)) {
        return console.log("llena los campos vacios");
      }     
    }
    const response = await Services.insertPettyCash(sale)
    .then(response => {
      if(response.status === 201){
        SoundPlay();
        clearInputs(flag);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Registro almacenado con exito',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }).catch(function (error){
      if(error.request.status === 500){
        return alert('Error interno, comunicate con el administrador') 
      }
      if(error.request.status === 422){
        return alert('El contenido no puede ser procesado, verificalos');
      }
    })
  }
  
  if(flag == 'buy'){
    if (!setFormerrorBuy(validate(buy, flag))) {
      const errorsb = validate(buy, flag);
      if (!(Object.entries(errorsb) == 0)) {
        return console.log("llena los campos vacios");
      }     
    }
    const response = await Services.insertPettyCash(buy)
    .then(response => {
      if(response.status === 201){
        SoundPlay();
        clearInputs(flag);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Registro almacenado con exito',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }).catch(function (error){
      if(error.request.status === 500){
        return alert('Error interno, comunicate con el administrador') 
      }
      if(error.request.status === 422){
        return alert('El contenido no puede ser procesado, verificalos');
      }
    })
  }

}

const validate = (values, flag) => {
  if(flag == 'sale'){
    const errors = {};
      if (values.description == 0) {
        errors.description = "El campo descripcion es requerido";
      }
      
      if (values.amount == 0) {
        errors.amount = "El campo cantidad es requerido";
      }
      
      if (values.price == 0) {
        errors.price = "El campo precio es requerido";
      }
      
      return errors;
  }

  if(flag == 'buy'){
    const errors = {};
      if (values.description == 0) {
        errors.description = "El campo descripcion es requerido";
      }
      
      if (values.amount == 0) {
        errors.amount = "El campo cantidad es requerido";
      }
      
      if (values.price == 0) {
        errors.price = "El campo precio es requerido";
      }
      
      return errors;
  }
}
/* finish functionality to insert a new sales or buy */

/* here we let's go to begin the configuration to get all utilities  */
const [utility, setUtility] = useState({
  date_begin: "",
  date_end: "",
  user_id: userIdInsert
});
const [getUitlities, setgetUtilities] = useState({
  utilitiesBuys: "",
  utilitiesSales: "",
  utility: "",
});
const [flag, setFlag] = useState(0)

const handleChangeUtility = (e) => {
    const {name, value} = e.target;
    setUtility(prevState => ({
        ...prevState,
        [name] : value
    })); 
  }

  const handleChangegetUitlities = (e) => {
    const {name, value} = e.target;
    setgetUtilities(prevState => ({
        ...prevState,
        [name] : value
    })); 
  }

async function getUtility(){
  const errors ={};
  if(utility.date_begin == 0){
    errors.date_begin ='La fecha de inicio no puede estar vacia';
  }

  if(utility.date_begin > utility.date_end){
    errors.date_begin ='La fecha de inicio no puede ser mayor a fecha final';
  }
  
  if(utility.date_end == 0){
    errors.date_end ='La fecha final no puede estar vacia';
  }

  if (!setFormerrorUtility(errors)) {
    if (!(Object.entries(errors) == 0)) {
      return console.log("llena los campos vacios utility");
    }     
  }

  const response = await Services.utilities(utility)
  .then(response => {
    if(response.status === 200){
      setgetUtilities(response.data)
      SoundPlayUtility();
      setFlag(1);
    }
  })
  .catch(function (error) {
    if(error.request.status === 500){
      return alert('Error interno, comunicate con el administrador') 
    }
    if(error.request.status === 422){
      return alert('El contenido no puede ser procesado, verificalos');
    }
  })
}
/* finish utilities module */

  function clearInputs(flag){
    const resetInputs = {
      description: "",
      amount: "",
      price: "",
      total: "",
      confirmed: "income",
      user_id: userIdInsert
    }
    if(flag == 'sale'){
      setSale(resetInputs);
      setTotal('')
    }
    if(flag == 'buy'){
      setBuy(resetInputs);
      setTotalBuy('')
    }

  }

  useEffect(() => {
    setTotal('');
    setTotalBuy('');
    setFlag(1);
  }, [])
  
  return (
    <div className='pt-4'>
      <div className='container-fluid'>
        <Row>

          <Col xs={12} md={4} lg={{ span: 3, offset: 1 }} >
            <Card className="text-center bgcardsale">
              <Card.Header>VENTAS</Card.Header>
              <Card.Body>
                <Card.Title>Registra tus ventas</Card.Title>
                
                <Card.Text>
                  <div className='container-fluid'>
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Descripción"
                    >
                      <Form.Control type="text" placeholder="Descripción" name='description' onChange={handleChange} value={sale && sale.description}/>
                    </FloatingLabel>
                    <p style={{color: 'red'}}>{formError.description}</p> 

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Cantidad"
                     
                    >
                      <Form.Control type="number" placeholder="Cantidad" name='amount' onChange={handleChange} onKeyDown={(e) => handleKeyDown(e)} value={sale && sale.amount}/>
                    </FloatingLabel>
                    <p style={{color: 'red'}}>{formError.amount}</p>  
                    
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Precio"
                     
                    >
                      <Form.Control type="number" placeholder="Precio" name='price' onChange={handleChange} onKeyDown={(e) => handleKeyDown(e)} value={sale && sale.price}/>
                    </FloatingLabel>
                    <p style={{color: 'red'}}>{formError.price}</p>  
                    <Button className="mb-2" variant="secondary" size="sm" onClick={()=>handleCalculateSale()}>Calcular total</Button>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="TOTAL"
                    >
                      <Form.Control type="number" disabled placeholder="TOTAL" name='total' onChange={handleChange} value={total} />
                    </FloatingLabel>
                    
                  </div>                               
                </Card.Text>
                <Button variant="primary" type="button" onClick={()=>insertPettyCash('sale')}>Registrar venta</Button>
              </Card.Body>
              <Card.Footer className="text-muted">Devops~dy</Card.Footer>
            </Card>            
          </Col>

          <Col xs={12} md={4} lg={3} >
            <Card className="text-center bgcardBuy">
              <Card.Header>COMPRAS</Card.Header>
              <Card.Body>
                <Card.Title>Registra tus compras</Card.Title>
                <Card.Text>
                <div className='container-fluid'>
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Descripción"
                   
                  >
                    <Form.Control type="text" placeholder="Descripción" name='description' onChange={handleChangeBuy} value={buy && buy.description} />
                  </FloatingLabel>  
                  <p style={{color: 'red'}}>{formErrorBuy.description}</p>

                  <FloatingLabel
                    controlId="floatingInput"
                    label="Cantidad"
                   
                  >
                    <Form.Control type="number" placeholder="Cantidad" name='amount' onChange={handleChangeBuy} onKeyDown={(e) => handleKeyDownBuy(e)} value={buy && buy.amount}/>
                  </FloatingLabel> 
                  <p style={{color: 'red'}}>{formErrorBuy.amount}</p>

                  <FloatingLabel
                    controlId="floatingInput"
                    label="Precio"
                   
                  >
                    <Form.Control type="number" placeholder="Precio" name='price' onChange={handleChangeBuy} onKeyDown={(e) => handleKeyDownBuy(e)} value={buy && buy.price}/>
                  </FloatingLabel>
                  <p style={{color: 'red'}}>{formErrorBuy.price}</p> 
                  <Button className="mb-2" variant="secondary" size="sm" onClick={()=>handleCalculateBuy()}>Calcular total</Button>
                  
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TOTAL" 
                  >
                    <Form.Control type="number" placeholder="TOTAL" disabled name='total' onChange={handleChangeBuy} value={totalBuy}/>
                  </FloatingLabel> 

                </div>
                </Card.Text>
                <Button variant="primary" onClick={()=>insertPettyCash('buy')}>Registrar compra</Button>
              </Card.Body>
              <Card.Footer className="text-muted">Devops~dy</Card.Footer>
            </Card>            
          </Col>

          <Col xs={12} md={4} lg={3} >
            <Card className="text-center bgcardUtility">
              <Card.Header>UTILIDADES</Card.Header>
              <Card.Body>
                <Card.Title>Consulta tus utilidades</Card.Title>
                <Card.Text>
                <div className='container-fluid'>

                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Fecha de inicio"
                    className="mb-3"
                  >
                    <Form.Control type="date" placeholder="Fecha de inicio" name='date_begin' onChange={handleChangeUtility} />
                  </FloatingLabel>
                  <p style={{color: 'red'}}>{formErrorUtility.date_begin}</p>  

                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Fecha final"
                    className="mb-3"
                  >
                    <Form.Control type="date" placeholder="Fecha final" name='date_end' onChange={handleChangeUtility}/>
                  </FloatingLabel>
                  <p style={{color: 'red'}}>{formErrorUtility.date_end}</p>  

                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total de ventas"
                    className="mb-3" 
                  >
                    <Form.Control type="number" placeholder="0.0" disabled name="utilitiesSales" onChange={handleChangegetUitlities} value={getUitlities && getUitlities.utilitiesSales} />
                  </FloatingLabel> 

                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total de compras"
                    className="mb-3" 
                  >
                    <Form.Control type="number" placeholder="0.0" disabled name="utilitiesBuys" onChange={handleChangegetUitlities} value={getUitlities && getUitlities.utilitiesBuys}/>
                  </FloatingLabel> 

                  <FloatingLabel
                    controlId="floatingInput"
                    label="Utilidad"
                    className="mb-3" 
                  >
                    <Form.Control type="number" placeholder="0.0" disabled name="utility" onChange={handleChangegetUitlities} value={getUitlities && getUitlities.utility}/>
                  </FloatingLabel> 

                </div>
                </Card.Text>
                <Button variant="primary" onClick={() => getUtility()}>Calcular utilidad</Button>
              </Card.Body>
              <Card.Footer className="text-muted">Devops~dy</Card.Footer>
            </Card>            
          </Col>

        </Row>
      </div>
    </div>
  )
}

export default Movements