import React, { useState } from 'react';
import '../App.css';
import LogoLight from '../img/LogoLigth.png';
import {Container, Row, Col, Image, Alert} from 'react-bootstrap';
import Services from '../api/Services';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {

    const [requestError, setRequestError] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [login, setLogin] = useState({
            email: "",
            password: "",
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setLogin(prevState => ({
            ...prevState,
            [name] : value
        }));
    }
    

    async function loginValidate(){
        if (!setFormErrors(validate(login))) {
            const error = validate(login);
            if (!(Object.entries(error) == 0)) {
              return console.log("llena los campos vacios");
            } 
          }
        
        const response = await Services.loginValidate(login)
        .then(response => {
            if (response.status === 200) {

                let timerInterval
                Swal.fire({
                  title: 'Usted sera redireccionado!',
                  html: 'En <b></b> milisegundos.',
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading()
                    const b = Swal.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => {
                      b.textContent = Swal.getTimerLeft()
                    }, 100)
                  },
                  willClose: () => {
                    clearInterval(timerInterval)
                  }
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    let token = response.data.access_token;
                    let user = response.data.user.name;
                    let user_id = response.data.user.id;
                    localStorage.setItem("token", token);
                    localStorage.setItem("user", user);
                    localStorage.setItem("user_id", user_id);
                    window.location.reload();
                    navigate('navbar');
                  }
                })

            }  

        }).catch(function (error){
          if(error.request.status == 500){
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Comunicate con los administradores',
              showConfirmButton: false,
              timer: 3500
            });
          }else{
            setRequestError(error.response.data.error);
            console.log(error.response.data.error);
          }
        });

    }

    function openModalRegister(){
        console.log(login)
    }

    const validate = (values) => {
        const errors = {};
        const regex =  /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (values.email == 0) {
          errors.email = "El campo email es requerido";
        }

        if (!regex.test(values.email)) {
          errors.email = "El campo email no tiene el formato correcto";
        } 
  
        if (values.password == 0) {
          errors.password = "El campo password es requerido";
        }

        if (values.password.length < 6) {
          errors.password = "El campo constraseña debe de ser mayor a 6 caracteres";
        }

        return errors;
      }

      function AlertErrorInsert() {
        return (
          <Alert variant="danger">
            <Alert.Heading>Lo sentimos! Hay un error!</Alert.Heading>
            <p>
                {Object.values(requestError).map((error, i) => <li key={i}>{error}</li>)}
            </p>
          </Alert>
        );
    }   

    function clearInputs(flag){
        const resetInputs = {
          email: "",
          password: ""
        }

        setLogin(resetInputs);
        setFormErrors(resetInputs); 
        setRequestError(resetInputs);
      }
  return (
    <div >
        <Container fluid>
            <Row>            
                <Col xs={12} md={5} lg={5} className="login-lefth">
                <div className='container-fluid' style={{paddingTop: '179px'}}> 
                <Row>
                    <Col xs={12} md={12} lg={{ span: 8, offset: 2 }}>
                    <h2>Hola!!!</h2>
                    <div className="badge bg-dark text-wrap mb-3 " style={{width: 'auto'}}>
                    Ingresa tu email y contraseña.
                    </div>
                        <div className="form-floating">
                          <input type="email" className="form-control" id="floatingInput" name='email' onChange={handleChange}/>
                          <label forhtml="floatingInput">Email</label>
                        </div>
                        <p style={{color: 'yellow'}}>{formErrors.email}</p>

                        <div className="form-floating">
                          <input type="password" className="form-control" id="floatingPassword" name='password' onChange={handleChange}/>
                          <label forhtml="floatingPassword">Contraseña</label>
                        </div>
                        <p style={{color: 'yellow'}}>{formErrors.password}</p>
                            
                        <div>
                          <button type="button" onClick={()=>loginValidate()} className="btn bg-greenbutton"><b>Iniciar Sesion</b></button>
                        </div>
                            
                        <div className='badge bg-redbutton mt-4' style={{width: 'auto'}}>
                          <Link className='tx-colorbutton' to='registerlogin'>Registrate aqui</Link>
                        </div>

                        {requestError.email &&
                          <div className='container mt-3'>
                            <AlertErrorInsert />
                          </div>                         
                        }
                          
                        {requestError.error &&
                          <div className='container mt-3'>
                            <AlertErrorInsert />
                          </div>                         
                        }
                    </Col>
                </Row> 

                    </div>
                </Col>
                <Col xs={12} md={7} lg={7} className="login-right">
                    <Image 
                        src={LogoLight}
                        fluid
                    />   
                </Col>
            </Row>
        </Container>        
    </div>
  )
}

export default Login