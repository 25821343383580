import MaterialTable from '@material-table/core'
import React from 'react'
import Swal from 'sweetalert2';

const TableAnimals = (props) => {

    function deleteAnimal(id, name){
     Swal.fire({
       title: 'Estas seguro de eliminar a: ' +name,
       text: "No podras revertir los cambios!",
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          props.deleteAnimalServic(id);
        }
      })
    }

const columns = [
    {title: 'Nombre', field: 'name'},
    {title: 'Fecha de nacimiento', field: 'date_birth'},
    {title: 'Fecha de apareamiento', field: 'date_mate'},
    {title: 'Fecha de vacuna', field: 'date_vacunation'},
    {title: 'Observaciones', field: 'observation'}
    ]

  return (
    <div>
{/* This is the table with material table to animals */}
        <div className='row pt-2'>
            <MaterialTable
                columns={columns}
                data={props.animals}
                title="Listado de animales"
                actions={[
                    {
                    icon: 'edit',
                    tooltip: 'Editar Animal',
                    onClick:(event, rowData)=>props.setModalShowEdit(true, props.setAnimalInsert(rowData))
                    },
                    {
                    icon: 'delete',
                    tooltip: 'Eliminar Animal',
                    onClick:(event, rowData)=>deleteAnimal(rowData.id, rowData.name)
                    }            
                ]}
                options= {{
                    actionsColumnIndex:-1
                }}
                localization={{
                    header:{
                    actions: 'Acciones'
                    }
                }}
            />
        </div>        
    </div>
  )
}

export default TableAnimals