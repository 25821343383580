import {Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Animals from './components/Animals';
import NavBar from './layouts/NavBar';
import Movements from './components/Movements';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import CredentialStorage from './components/CredentialStorage';
import { useEffect, useState } from 'react';
import RegisterLogin from './components/RegisterLogin';

function App() {

  const [isLogged, setIsLogged] = useState(false);
  
  useEffect(()=>{
    if(CredentialStorage.getUserStorage()){
      setIsLogged(true)
    }else{
      setIsLogged(false)
    }
  }, []);


  return (
    <div>
      <Routes>
        {!isLogged && (
          <Route path='/' element={<Login />} />
        )}

        {!isLogged && (
          <Route path='registerlogin' element={<RegisterLogin />} />
        )}
        
        <Route element={<PrivateRoute isLogged={isLogged} />}>
          <Route path='navbar' element={<NavBar />}>
            <Route index element ={<Animals />} />
            <Route path='animals' element ={<Animals />} />
            <Route path='movements' element ={<Movements />} />
          </Route>
        </Route>

          <Route path='*' element ={<Navigate replace to={isLogged ? "navbar" : "/"} />} />
      </Routes>
    </div>
  );
}

export default App;
