import React, { useEffect, useState } from 'react'
import AddAlertIcon from '@mui/icons-material/AddAlert';
import Services from '../api/Services';
import ModalAlerts from './ModalAlerts';
import CredentialStorage from './CredentialStorage';


const Notifications = () => {

  const [user_id, setUserId] = useState({
    user_id: CredentialStorage.getUserId(), 
  });
  const [dateBirth, setdateBirth] = useState({});
  const [alertDateMate, setAlertDateMate] = useState({});
  const [alertDateVacunation, setalertDateVacunation] = useState({});

  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState({});

  async function getAlertDate(){
    const response = await Services.getAlertDate(user_id)
    .then(response => {
      setdateBirth(response.data.dateBirth);
      setAlertDateMate(response.data.alertDateMate)
      setalertDateVacunation(response.data.alertDateVacunation);
    }).catch(function (error){
      if(error.request.status === 500){
        return console.log('Problema interno');
      }

      if(error.request.status === 429){
        return console.log('Problema de conexion')
      }
    })
  } 

  useEffect(()=>{
    getAlertDate()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
        getAlertDate();
    }, 30000);
  }, [])
  
  return (
    <div>     
      <div>
        <button onClick={()=>setShow(true, setFlag(dateBirth))} type="button" className="btn badge bg-primary position-relative">
        NACIMIENTO<AddAlertIcon /> 
            {dateBirth.length > 0 && 
            <span className="position-absolute end-0  translate-middle badge rounded-pill bg-danger">
              {dateBirth.length }
              <span className="visually"> alertas</span>
            </span>
          }
        </button>         

        <button onClick={()=>setShow(true, setFlag(alertDateMate))} type="button" className="btn badge bg-primary position-relative" style={{marginLeft: '15px'}}>
        APAREAR<AddAlertIcon />
        {alertDateMate.length > 0 &&
          <span className="position-absolute end-0 translate-middle badge rounded-pill bg-danger">
            {alertDateMate.length}
            <span className="">  alertas</span>
        </span>
          }
        </button>         

        <button onClick={()=>setShow(true, setFlag(alertDateVacunation))} type="button" className="btn badge bg-primary position-relative" style={{marginLeft: '15px'}}>
        VACUNAR<AddAlertIcon /> 
        {alertDateVacunation.length > 0 &&
           <span className="position-absolute end-0 translate-middle badge rounded-pill bg-danger">
            {alertDateVacunation.length}
              <span className="visually">  alertas</span>
          </span>
        } 
        </button>         
      </div>
        <ModalAlerts
        show={show}
        setShow={setShow}
        alertDateMate={alertDateMate}
        flag={flag}
        />
      <div>
      </div>
    </div>
  )
}

export default Notifications