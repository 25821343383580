import React from 'react'
import { Outlet, NavLink } from 'react-router-dom';
import logo from '../img/LogoLigth.png';
import Notifications  from '../components/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import Services from '../api/Services';
import '../App.css'; 

const NavBar = () => {
    const navigate = useNavigate();
    async function logoutServer(){
            await Services.logoutServer()
            .then(response => {
                console.log(response)
            })
            .catch(function(error){
                console.log(error)
            })
    }
    
    const logout = ()=>{
        logoutServer();
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.reload();
        navigate('/');
    }


  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-navbar">
        <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
            <img src={logo} alt="" width="95" height="65" className="d-inline-block align-text-top" />
        </NavLink>

            
        <div className="navbar-nav col-md-6 mx-auto">
            <NavLink className="btn btn-sm btn-outline-danger me-2 bg-dark" type="button" to="animals">Animales</NavLink>
            <NavLink className="btn btn-sm btn-outline-danger me-2 bg-dark" type="button" to="movements">Movimientos finacieros</NavLink>
        </div>
        <div className="justify-content-center mx-auto pt-3">
            <Notifications />
        </div>          
        <div className="justify-content-center mx-auto pt-3">
           <a type='button' className='btn btn-dark position-relative badge' onClick={() =>logout()}>  
            <LogoutIcon />
           </a>
        </div>
        </div>
        </nav>
        <section>
            <Outlet />
        </section>        
    </div>
  )
}

export default NavBar