import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Services from '../api/Services';
import {Modal, Button, Alert, Stack } from 'react-bootstrap';
import TableAnimals from './TableAnimals';
import apple from '../sounds/Apple.mp3';
import { Howl } from 'howler';
import CredentialStorage from '../components/CredentialStorage';

const Animals = () => {
  const userIdInsert = CredentialStorage.getUserId(); 
  const [user_id, setUserId] = useState({
    user_id: CredentialStorage.getUserId(), 
  });

  //function for alert date
  const [dateBirth, setdateBirth] = useState([]);
  const [alertDateMate, setAlertDateMate] = useState([]);
  const [alertDateVacunation, setalertDateVacunation] = useState([]);
  
  async function getAlertDate(){
    const response = await Services.getAlertDate(user_id)
    .then(response => {
      setdateBirth(response.data.dateBirth);
      setAlertDateMate(response.data.alertDateMate)
      setalertDateVacunation(response.data.alertDateVacunation);
    }).catch(function(error){
      if(error.request.status === 500){
        return Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Problema interno, comunicate con el admin.',
          showConfirmButton: false,
          timer: 3500
        }); 
      }
      
      if(error.request.status === 429){
           return console.log('cargando...')
      }
    })
  } 
  
  function SoundPlay() {
    const Sounds = new Howl({
      src: [apple]
    })
    Sounds.play()
  }

  useEffect(() => {
    getAlertDate();
  }, []);

//finish function for alert date

   
    const [animals, setAnimals] = useState([]);
    
    const [requestError, setRequestError] = useState({});
    const [formErrors, setFormErrors] = useState({});

    const [animalInsert, setAnimalInsert] = useState({
                                                      id: "",
                                                      name: "",
                                                      date_birth: "",
                                                      date_mate: "",
                                                      date_vacunation: "",
                                                      observation: "",
                                                      user_id: userIdInsert,
                                                      status: 1  
                                                    });

    const [modalShow, setModalShow] = useState(false);    
    const [modalShowEdit, setModalShowEdit] = useState(false);    

    const handleChange = (e) =>{
      const {name, value} = e.target;
      setAnimalInsert(prevState => ({
        ...prevState,
        [name] : value
      }));
      
    }

    async function getData() {
        try {
          const response = await Services.getAnimals(user_id)
          setAnimals(response.data.animals);
        } catch (error) {
          if(error.request.status === 500){
            return Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Problema interno, comunicate con el admin.',
              showConfirmButton: false,
              timer: 3500
            }); 
          }

          if(error.request.status === 429){
            console.log('cargando...')
          }
        }
      }

    async function setDataPostInsert(){
      
      if (!setFormErrors(validate(animalInsert))) {
        const error = validate(animalInsert);
        if (!(Object.entries(error) == 0)) {
          return console.log("llena los campos vacios");
        } 
      }

    const response = await Services.insertAnimalsURL(animalInsert)
      .then(response => {
        if (response.status === 201) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Registro almacenado con exito',
            showConfirmButton: false,
            timer: 1500
          })
      }

      getData();
      clearInputs('create');
      SoundPlay()

      })
      .catch(function (error) {
        if(error.request.status === 500){
          return Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Problema interno, comunicate con el admin.',
            showConfirmButton: false,
            timer: 3500
          }); 
        }

      if(error.request.status === 401){
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'No tienes permisos para esta acción',
          showConfirmButton: false,
          timer: 3500
        });
      }else{
        setRequestError(error.response.data.error);
        }        
      })      
    };


    async function updateAnimal(){
      if (!setFormErrors(validate(animalInsert))) {
        const error = validate(animalInsert);
        if (!(Object.entries(error) == 0)) {
          return console.log("llena los campos vacios");
        } 
      }
      
      const response = await Services.updateAnimalsURL(animalInsert, animalInsert.id)
        .then(response => {
          if (response.status === 200) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Registro actuaizado con exito',
              showConfirmButton: false,
              timer: 1500
            })
            getData();
            clearInputs('edit');
            SoundPlay()
          }        
        })
        .catch(function (error) {
          if(error.request.status === 500){
            return Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Problema interno, comunicate con el admin.',
              showConfirmButton: false,
              timer: 3500
            }); 
          }

        if(error.request.status === 401){
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'No tienes permisos para esta acción',
            showConfirmButton: false,
            timer: 3500
          });
         
        }else{
          setRequestError(error.response.data.error);
        }
      })
    } 

    async function deleteAnimalServic(id){
      const response = await Services.deleteAnimalURL(id)
      .then(response => {
          if (response.status === 200) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Registro eliminado con exito',
              showConfirmButton: false,
              timer: 1500
            })
            getData();
        }        
        
      })
      .catch(function (error) {
        if(error.request.status === 500){
          return Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Problema interno, comunicate con el admin.',
            showConfirmButton: false,
            timer: 3500
          });          
        }
        if(error.request.status === 401){
          return Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'No tienes permisos para esta acción',
            showConfirmButton: false,
            timer: 3500
          });

        }else{
          return setRequestError(error.response.data.error);
        }        
      })       
    }

    const validate = (values) => {
      const errors = {};
      const regex =  /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (values.name == 0) {
        errors.name = "El campo nombre es requerido";
      }

      if (values.date_birth == 0) {
        errors.date_birth = "El campo fecha de nacimiento es requerido";
      }

      if (values.date_mate == 0) {
        errors.date_mate = "El campo fecha para aparear es requerido";
      }

      if (values.date_vacunation == 0) {
        errors.date_vacunation = "El campo fecha para vacunar es requerido";
      }

      if (values.observation == 0) {
        errors.observation = "El campo observaciones es requerido";
      }

      return errors;
    }
//function for set show in alert
    function AlertErrorInsert() {
      return (
        <Alert variant="danger">
          <Alert.Heading>Lo sentimos! Hay un error!</Alert.Heading>
          <p>
              {Object.values(requestError).map((error, i) => <li key={i}>{error}</li>)}
          </p>
        </Alert>
      );
  }

    function clearInputs(flag){
      const resetInputs = {
        id: "",
        name: "",
        date_birth: "",
        date_mate: "",
        date_vacunation: "",
        observation: "",
        user_id: "",
        status: 1  
      }
      if (flag === 'create') {
        setModalShow(false); 
      }
      if (flag === 'edit') {
        setModalShowEdit(false); 
      }
      setAnimalInsert(resetInputs);
      setFormErrors(resetInputs); 
      setRequestError(resetInputs);
    }

     useEffect(() => {
        getData();
     }, []) 


  return (
    <div className='container-fluid'>

{/* This is a button to ope de modal create animal */}
      <div className='mt-3'>
      <Stack gap = {1} className="col-md-5 mx-auto">
        <Button variant ="outline-primary" onClick={() => setModalShow(true)}>
        Registrar animal
        </Button>   
      </Stack>
      </div>

{/* This is the table with material table to animals */}
      <div className='container mt-1'>
        <TableAnimals
        animals = {animals}
        setModalShowEdit= {setModalShowEdit}
        setAnimalInsert= {setAnimalInsert}
        deleteAnimalServic={deleteAnimalServic}
        />
      </div>
      
{/* This is a modal to crear a new animals with react bostroap */}
      <div className='container'>
      <Modal
          show= {modalShow}
          onHide = {() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered 
          backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Insertar animal aqui.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="form-floating mb-1">
                  <input type="text" className="form-control" id="floatingInput" placeholder="Nombre del animal" name='name' onChange={handleChange}  />
                  <label htmlFor="name">Nombre del animal</label>
                </div>
                <p style={{color: 'red'}}>{formErrors.name}</p>
            
                <div className="form-floating mb-1">
                  <input type="date" className="form-control" id="floatingPassword" name='date_birth' onChange={handleChange}  />
                  <label htmlFor="date_birth">Fecha de nacimiento</label>
                </div> 
                <p style={{color: 'red'}}>{formErrors.date_birth}</p>

                <div className="form-floating mb-1">
                  <input type="date" className="form-control" id="floatingPassword" name='date_mate' onChange={handleChange}  />
                  <label htmlFor="date_mate">Fecha para aparear</label>
                </div>
                <p style={{color: 'red'}}>{formErrors.date_mate}</p>

                <div className="form-floating mb-1">
                  <input type="date" className="form-control" id="floatingPassword" name='date_vacunation' onChange={handleChange} />
                  <label htmlFor="date_vacunation">Fecha para vacunar</label>
                </div>
                <p style={{color: 'red'}}>{formErrors.date_vacunation}</p>

                <div className="form-floating mb-1">
                  <input type="text" className="form-control" id="floatingPassword" placeholder="Observaciones" name='observation' onChange={handleChange} />
                  <label htmlFor="observation">Observaciones</label>
                </div>
                <p style={{color: 'red'}}>{formErrors.observation}</p>
          </Modal.Body>
          <Modal.Footer>
            {requestError.message &&
            <div className='container mt-3'>
                <AlertErrorInsert />
            </div>                         
            }              
              {requestError.name &&
              <div className='container'>
                  <AlertErrorInsert />
              </div>
              }
            <Button variant="secondary" onClick={() => clearInputs('create')}
              >Close</Button>
            <button type="button" onClick={()=>setDataPostInsert()} className="btn btn-primary">Guardar</button>
          </Modal.Footer>
        </Modal>        
      </div> 

{/* This is a modal to edit an animals with react bostroap */}
<div className='container'>
      <Modal
          show= {modalShowEdit}
          onHide = {() => setModalShowEdit(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered 
          backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Actualizar a: <b>{animalInsert && animalInsert.name}</b> 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="form-floating mb-1">
                  <input type="text" className="form-control" id="floatingInput" placeholder="Nombre del animal" name='name' onChange={handleChange} value={animalInsert && animalInsert.name } />
                  <label htmlFor="name">Nombre del animal</label>
                </div>
                <p style={{color: 'red'}}>{formErrors.name}</p>
            
                <div className="form-floating mb-1">
                  <input type="date" className="form-control" id="floatingPassword" name='date_birth' onChange={handleChange} value={animalInsert && animalInsert.date_birth} />
                  <label htmlFor="date_birth">Fecha de nacimiento</label>
                </div> 
                <p style={{color: 'red'}}>{formErrors.date_birth}</p>

                <div className="form-floating mb-1">
                  <input type="date" className="form-control" id="floatingPassword" name='date_mate' onChange={handleChange} value={animalInsert && animalInsert.date_mate} />
                  <label htmlFor="date_mate">Fecha para aparear</label>
                </div>
                <p style={{color: 'red'}}>{formErrors.date_mate}</p>

                <div className="form-floating mb-1">
                  <input type="date" className="form-control" id="floatingPassword" name='date_vacunation' onChange={handleChange} value={animalInsert && animalInsert.date_vacunation }/>
                  <label htmlFor="date_vacunation">Fecha para vacunar</label>
                </div>
                <p style={{color: 'red'}}>{formErrors.date_vacunation}</p>

                <div className="form-floating mb-1">
                  <input type="text" className="form-control" id="floatingPassword" placeholder="Observaciones" name='observation' onChange={handleChange}  value={animalInsert && animalInsert.observation}/>
                  <label htmlFor="observation">Observaciones</label>
                </div>
                <p style={{color: 'red'}}>{formErrors.observation}</p>
          </Modal.Body>
          <Modal.Footer>
            <div className='container'>
              {requestError.name &&
                <AlertErrorInsert />
              }
            </div>
            <Button variant="secondary" onClick={() => clearInputs('edit') }>Close</Button>
            <button type="button" onClick={()=>updateAnimal()} className="btn btn-primary">Guardar</button>
          </Modal.Footer>
        </Modal>        
      </div> 
    </div>
  )
}

export default Animals