import { Alert, Button, Modal } from 'react-bootstrap'

const ModalAlerts = (props) => {
    
  return (
    <div>
        <Modal show={props.show} onHide={()=>props.setShow(false)}>
          <Modal.Header closeButton>
          <Modal.Title>Alertas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {props.flag.length > 0 ?
                Object.values(props.flag).map((alert, i) => 
                <Alert variant="primary" style={{color:'black'}} key={i}><b style={{color:'black'}}>{alert.name}</b> tiene algo pendiente el: <b style={{color:'red'}}>{alert.date}</b> </Alert>
                )  
                : <Alert variant="success" style={{color:'black'}}><b>No tienes notificaciones!!!</b></Alert>
              }
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={()=>props.setShow(false)}>
              Cerrar
          </Button>
          </Modal.Footer>
        </Modal>   
    </div>
  )
}

export default ModalAlerts