import axios from 'axios';
import CredentialStorage from '../components/CredentialStorage';
const url_base = "https://apicontrolanimales.devopsdy.com/api";
const token = CredentialStorage.getTokenStorage();

const instance = axios.create({
     headers: {
       accept:'Aplication/json',
       Authorization: `Bearer ${token}`
     }
})

export default {
     getAnimals: (user_id) => {return axios.post(`${url_base}/animalsAll`, user_id)},
     insertAnimalsURL: (animals) => {return instance.post(`${url_base}/animals`, animals)},
     updateAnimalsURL: (animals, id) => {return instance.put(`${url_base}/animals/${id}`, animals) },
     deleteAnimalURL: (id) => {return instance.put(`${url_base}/updateStatus/${id}`)},

     getAlertDate: (user_id) => {return axios.post(`${url_base}/alertDate`, user_id)},

     loginValidate: (login) => {return axios.post(`${url_base}/login`, login)},
     logoutServer: () => {return instance.post(`${url_base}/logout`)}, 
     loginAdd: (login) => {return axios.post(`${url_base}/createLogin`, login)},

     getPettycash: (data) => {return axios.post(`${url_base}/pretycashAll`, data)}, 
     insertPettyCash: (data) => {return instance.post(`${url_base}/pretycash`, data)}, 
     utilities: (data) => {return instance.post(`${url_base}/utility`, data)}, 
}

